import React from 'react'

import Gallery from '../../components/common/gallery';

const Colombia = () => (
  <Gallery
    imageLinks={
      [1, 2, 3, 4, 5, 6, 7].map((number) => (
        `https://s3.amazonaws.com/valentina-site/artisans_images/colombia/Colombia${number}.jpg`
      ))
    }
    navigationColor='#c3c6cc'
    fromText='Artisans &amp; World'
    fromLink='/artisans_and_world'
  />
)

export default Colombia